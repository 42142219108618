// Form Component
export default class Form {
    constructor() {
        this.form = document.querySelector('form');
        this.inputContainer = document.querySelector('#form__input-container');
        this.errorMessage = document.querySelector('#error-message');
        this.zip = this.form.querySelector('#zip');
    }

    isValidZip() {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.zip.value);
    }

    eventListeners() {
        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            if (this.isValidZip()) {
                this.errorMessage.style.display = '';
                this.inputContainer.classList.remove('error');
                this.form.submit();
            } else {
                this.errorMessage.style.display = 'block';
                this.inputContainer.classList.add('error');
            }
        });
    }

    init() {
        this.eventListeners();
    }
}
