// Sticky Phone Component
export default class StickyPhone {
    constructor() {
        this.stickyPhone = document.querySelector('[data-sticky-phone]');
        this.phoneTrigger = document.querySelector('[data-phone-trigger]');
    }

    isOutOfViewport() {
        // Get element's bounding
        const bounding = this.phoneTrigger.getBoundingClientRect();
        // Check if it's out of the viewport on each side
        const out = {};
        out.top = bounding.top < 0;
        out.left = bounding.left < 0;
        out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
        out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
        out.any = out.top || out.left || out.bottom || out.right;

        return out;
    }

    togglePhoneNumber() {
        const isOut = this.isOutOfViewport();
        if (isOut.any) {
            this.stickyPhone.classList.add('show');
        } else {
            this.stickyPhone.classList.remove('show');
        }
    }

    eventListeners() {
        window.addEventListener('scroll', this.togglePhoneNumber.bind(this), false);
    }

    init() {
        if (window.matchMedia('(max-width: 1023px)').matches) {
            this.eventListeners();
        }
    }
}
