// Import SCSS so they get compiled
import '../scss/styles.scss';
// Lazy Load images
// import 'lazysizes'; // Not needed for only 1 image
import StickyPhone from './components/sticky-phone';
import Form from './components/form';

const isIE11 = Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);
if (isIE11) {
    document.documentElement.classList.add('js-ie11');
}

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function init() {
    const sticky = new StickyPhone();
    sticky.init();
    const form = new Form();
    form.init();
}

ready(init);